import React from "react"
import styled from "@emotion/styled"
import gs from "./GlobalStyles"

const DivVideoContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
`

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  @media all and (min-width: ${gs.size.screen.md}) {
    //margin-top: -32px;
  }
`

export function Video1() {
  return (
    <DivVideoContainer>
      <Iframe
        src="https://player.vimeo.com/video/517382281"
        title="Megan Carnes Game Music Reel"
      />
    </DivVideoContainer>
  )
}

export function Video2() {
  return (
    <DivVideoContainer>
      <Iframe
        src="https://player.vimeo.com/video/276116165"
        title="Megan Carnes Film Music Demo Reel"
      />
    </DivVideoContainer>
  )
}
