import * as React from "react"
import gs from "../components/styling/GlobalStyles"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import styled from "@emotion/styled"
import { Video1, Video2 } from "../components/styling/Video"
import BtnBorder from "../images/BtnBorderLG.png"
import Florals from "../images/LogoFloral.png"
import Underline from "../images/SubtitleLine.png"
import { Link } from "gatsby"

// #region styling
const DivHeadingWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  align-items: center;
  @media all and (min-width: ${gs.size.screen.md}) {
    align-items: start;
    padding: 0 16px;
  }
`
const H1Title = styled.h1`
  margin: 8px 0 0 0;
  width: 100%;
  line-height: 1.1;
  width: fit-content;
  position: relative;
  &::after {
    content: "";
    pointer-events: none;
    background-image: url(${Florals});
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: 105.4%;
    height: 100%;
    left: -2.8%;
    top: -6.4%;
  }
  font-size: 3rem;
  @media all and (min-width: ${gs.size.screen.sm}) {
    font-size: 4rem;
  }
  @media all and (min-width: ${gs.size.screen.md}) {
    font-size: 5rem;
  }
`
const SpanSubtitle = styled.span`
  display: flex;
  gap: 0 8px;
  position: relative;
  &::after {
    content: "";
    display: inline-block;
    pointer-events: none;
    background-image: url(${Underline});
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    top: -4px;
    width: 260px;
    height: 30px;
  }
  flex-flow: column wrap;
  align-items: center;
  margin: 0 0 20px 0;
  @media all and (min-width: ${gs.size.screen.md}) {
    margin: 0 0 32px -16px;
    align-items: flex-start;
    flex-flow: row nowrap;
    &::after {
      top: -8px;
      width: 300px;
      height: 45px;
    }
  }
`
const PSubtitle = styled.p`
  color: ${gs.color.graey};
  font-weight: 300;
  width: 100%;
  padding: 0;
  position: relative;
  width: fit-content;
  margin: 0;
  font-size: ${gs.size.font.xl};
  @media all and (min-width: ${gs.size.screen.md}) {
    font-size: ${gs.size.font.xxl};
  }
`
const Section = styled.section`
  display: flex;
  flex-flow: column wrap;
`
const DivTwoCol = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  margin: 0 0 16px 0;
  flex-wrap: wrap;
  gap: 16px;
  @media all and (min-width: ${gs.size.screen.md}) {
    gap: 4px;
    flex-wrap: nowrap;
  }
  @media all and (min-width: ${gs.size.screen.lg}) {
    gap: 16px;
  }
  iframe {
    width: 100%;
  }
`
const DivVideoWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
`
const DivStinger = styled.div`
  text-align: center;
  margin: 32px 0;
`
const LinkMain = styled(Link)`
  ${gs.font.alt};
  transition: 700ms all ease-in-out;
  z-index: 3;
  line-height: 1.2;
  font-size: 2rem;
  @media all and (min-width: ${gs.size.screen.md}) {
    font-size: 2.3rem;
  }
  padding: 8px 16px;
  position: relative;
  color: ${gs.color.white};
  text-decoration: none;
  &::before,
  &::after {
    content: "";
    transition: 920ms width ease-in-out;
    pointer-events: none;
    position: absolute;
    background-image: url(${BtnBorder});
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    height: 90px;
    width: 28px;
    @media all and (min-width: ${gs.size.screen.md}) {
      width: 32px;
      height: 100px;
    }
  }
  &::before {
    top: -10%;
    left: -5%;
    transform: scaleX(-1) scaleY(-1);
  }
  &::after {
    bottom: -10%;
    right: -5%;
  }
  &:not(.active):not(:disabled):hover,
  &:not(.active):not(:disabled):focus-visible,
  &:not(.active):not(:disabled):active {
    color: ${gs.color.darkGreen};
    &::before,
    &::after {
      width: 266px;
    }
    @media all and (min-width: ${gs.size.screen.md}) {
      &::before,
      &::after {
        width: 295px;
      }
    }
  }
`
// #region styling

/**
 * @returns The home/index page
 */
const IndexPage = () => {
  return (
    <Layout>
      <Section>
        <DivHeadingWrapper>
          <H1Title>Megan Carnes</H1Title>
          <SpanSubtitle>
            <PSubtitle>Music for Media</PSubtitle>
          </SpanSubtitle>
        </DivHeadingWrapper>
        <DivTwoCol>
          <DivVideoWrapper>
            <Video1 />
          </DivVideoWrapper>
          <DivVideoWrapper>
            <Video2 />
          </DivVideoWrapper>
        </DivTwoCol>
        <DivStinger>
          <LinkMain to="/music">Explore My Music</LinkMain>
        </DivStinger>
      </Section>
    </Layout>
  )
}

export default IndexPage

export const Head = () => <Seo />
